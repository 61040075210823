<div class="header">
    <div class="logo logo-dark">
        <a href="">
            <img src="../../../../../assets/images/logo/logo.png" alt="Logo">
            <img class="logo-fold" src="../../../../../assets/images/logo/logo-fold.png" alt="Logo">
        </a>
    </div>
    <div class="nav-wrap">
        <ul class="nav-left">
            <li class="desktop-toggle" *ngIf="showNavToggle">
                <a (click)="toggleFold()">
                    <i nz-icon [nzType]="isFolded? 'menu-unfold':'menu-fold'" nzTheme="outline"></i>
                </a>
            </li>
            <li class="mobile-toggle" *ngIf="showNavToggle">
                <a (click)="toggleExpand()">
                    <i nz-icon [nzType]="isExpand? 'menu-fold': 'menu-unfold'" nzTheme="outline"></i>
                </a>
            </li>
        </ul>
        <ul class="nav-right">
           
            <li>
                <span class="p-h-10 pointer" nz-dropdown [nzDropdownMenu]="profile" [nzTrigger]="'click'" [nzPlacement]="'bottomRight'">
                    <nz-avatar nzIcon="user" style="background-color:#3f87f5;"></nz-avatar>
                </span>
                <nz-dropdown-menu #profile="nzDropdownMenu">
                    <ul nz-menu class="p-b-15 p-t-20">
                        <li class="p-h-20 p-b-15 m-b-10 border-bottom" *ngIf="usuarioAutenticado$ | async as usuario">
                            <div class="d-flex m-r-50">                        
                                <nz-avatar nzIcon="user" style="background-color:#3f87f5;"></nz-avatar>
                                <div class="m-l-10">
                                    <p class="m-b-0 text-dark font-weight-semibold">{{usuario.nome}}</p>
                                    <p class="m-b-0 opacity-07">
                                        {{usuario.nomeOrganizacao}} 
                                    </p>
                                    <p class="m-b-0 opacity-07">
                                        {{usuario.nomeSetor}} <a (click)="trocarSetor()" title="Trocar Setor"><i nz-icon nzType="redo" nzTheme="outline"></i></a>
                                    </p>
                                </div>
                            </div>
                        </li>                    
                        <li nz-menu-item (click)="logout()">
                            <a class="p-v-5 d-flex align-items-center justify-content-between">
                                <div>
                                    <i class="opacity-04 font-size-16" nz-icon nzType="logout" nzTheme="outline"></i>
                                    <span class="m-l-10">Sair</span>
                                </div>
                            </a>
                        </li>
                    </ul>
                </nz-dropdown-menu >
            </li>
        </ul>
    </div>
</div>    